<template>
  <div class="page-list">
    <!--  面包屑  -->
    <div
      class="mrj-layout-tabs-custom flex align-item-center"
      style="z-index:1;"
    >
      <div
        @click="changeRoute"
        class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer"
      >
        <div
          type="left"
          class="leftarrow"
          :class="imgflag?'leftarrow_hover':''"
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >
          <i
            class="meiye-icon meiye-fanghui"
            style="margin-left:-1px;"
          ></i>
        </div>
        <span
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >{{ $route.meta.title }}</span>
      </div>
      <span class="mrj-layout-tabs-custom-fenge">/</span>
      <span class="mrj-layout-tabs-custom-inner-page">添加</span>
    </div>
    <!-- 内容区 -->
    <div class="add">
      <h2 class="twoh2">
        <span class="tpointer"></span>
        <span class="ttxt">基本配置</span>
      </h2>
      <a-row
        class="filter-wrap"
        type="flex"
      >
        <a-col :span="10">
          <a-form-model
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 12 }"
            labelAlign="left"
          >
            <a-form-model-item
              label="录入门店"
              required
            >
              <a-select
                class="width-min-255"
                v-model="data.institution_id"
                placeholder="选择门店"
              >
                <a-select-option
                  v-for="it in shopsList"
                  :key="it.institution_id"
                  :value="it.institution_id"
                >
                  {{ it.shop_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              label="姓名"
              required
            >
              <a-input
                class="width-min-255"
                v-model="data.name"
                placeholder="输入合伙人姓名"
                :maxLength="20"
              />
            </a-form-model-item>
            <a-form-model-item
              label="手机号"
              required
            >
              <a-input
                class="width-min-255"
                type="number"
                v-model="data.phone"
                placeholder="输入合伙人手机号"
                :maxLength="11"
              />
            </a-form-model-item>
            <a-form-model-item label="持卡人姓名">
              <a-input
                class="width-min-255"
                v-model="data.bank_card_name"
                placeholder="输入合伙人持卡人姓名"
                :maxLength="20"
              />
            </a-form-model-item>
            <a-form-model-item label="银行卡号">
              <a-input
                class="width-min-255"
                v-model="data.bank_card_no"
                placeholder="输入合伙人银行卡号"
                :maxLength="30"
              />
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="14">
          <a-form-model
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 18 }"
            labelAlign="left"
          >
            <a-form-model-item label="录入人">{{name}}</a-form-model-item>
            <a-form-model-item
              label="消费股"
              required
            >
              <a-input-number
                style="width:162px"
                :disabled="!data.institution_id"
                :min="0"
                v-model.number="data.subscribed_shares"
                placeholder="输入认购股数"
              />
              <span style="font-size: 14px;color: @fontColor5;margin-left:10px">¥{{subscription_setting?.share_price || '0'}}元/股</span>
            </a-form-model-item>
            <a-form-model-item
              label="认购金"
              required
            >
              <a-input
                class="width-min-255"
                disabled
                type="number"
                v-model="data.subscription_fee"
                placeholder="根据股数自动计算"
                suffix="元"
              />
            </a-form-model-item>
            <a-form-model-item
              label="付款方式"
              required
            >
              <a-select
                class="width-min-255"
                v-model="data.pay_type"
                placeholder="选择付款方式"
              >
                <a-select-option
                  v-for="it in paymentList"
                  :key="it.pay_type"
                  :value="it.pay_type"
                >
                  {{ it.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="开户行">
              <a-input
                class="width-min-255"
                v-model="data.bank_name"
                placeholder="输入合伙人开户行"
              />
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>
      <div style="margin-left:11.6%">
        <a-button
          type="primary"
          @click="handleOkAdd"
        >
          确认
        </a-button>
        <a-button
          class="mgl-20"
          @click="changeRoute"
        >
          取消
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import Msg from '@/components/mrj-menu/msg'
import { partnerArchivesAdd, partnerArchivesShopList } from '@/api/partnerfile'
import storage from 'store'
export default {
  data() {
    return {
      imgflag: false,
      paymentList: [],
      shopsList: [],
      name: JSON.parse(localStorage.getItem("mrj_user_name")) || '',
      subscription_setting: '',
      data: {
        institution_id: undefined,
        partner_plan_id: undefined,
        name: undefined,
        phone: undefined,
        subscribed_shares: undefined,
        subscription_fee: undefined,
        pay_type: undefined,
        bank_card_id: undefined,
        bank_card_name: undefined,
        bank_card_no: undefined,
        bank_name: undefined,
      }
    };
  },
  watch: {
    'data.institution_id': {
      handler(val) {
        let str = this.shopsList.find(v => v.institution_id == val)
        this.data.partner_plan_id = str.partner_plan_id;
        this.data.subscribed_shares = Number(str.subscription_setting.min_shares);
        this.subscription_setting = str.subscription_setting;
      },
      deep: true
    },
    'data.subscribed_shares': {
      handler(val) {
        this.data.subscription_fee = Number(val) * Number(this.subscription_setting?.share_price || 1)
      },
      deep: true
    }
  },
  created() {
    this.getPayList();

  },
  methods: {
    getPayList() {
      partnerArchivesShopList().then(res => {
        this.shopsList = res.data.shops;
        this.paymentList = res.data.payments;
        if(this.shopsList.length){
          this.data.institution_id = this.shopsList[0].institution_id
        }
      })
    },
    isMobile(value) {
      return /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(value);
    },
    handleOkAdd() {
      if (!this.data.institution_id) return this.$message.warning('请选择门店')
      if (!this.data.name) return this.$message.warning('请填写姓名！')
      if (!this.data.subscribed_shares) return this.$message.warning('请填写消费股！')
      if (this.data.subscribed_shares < this.subscription_setting.min_shares) return this.$message.warning('填写的消费股不能小于' + this.subscription_setting.min_shares + '股')
      if (!this.isMobile(this.data.phone)) return this.$message.warning('请填写正确的手机号！')
      if (!this.data.subscription_fee) return this.$message.warning('请填写认购金！')
      if (!this.data.pay_type) return this.$message.warning('请选择付款方式')
      partnerArchivesAdd(this.data).then(res => {
        this.$message.success('添加成功')
        this.changeRoute()
      })
    },
    changeRoute() {
      console.log('this.$route.fullpath :>> ', this.$route.fullpath);
      this.$router.push({ path: this.$route.fullpath });
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)
    }
  },
};
</script>

<style scoped lang="less">
.add {
  font-family: PingFangSC-Medium, PingFang SC;
  margin: 12px 12px 0 12px;
  padding: 30px 30px 13px;
  background: #fff;
  height: calc(100vh - 132px);
}
.twoh2 {
  display: flex;
  margin-bottom: 28px;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin: 5px 5px 0 0;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-weight: 600;
    color: @fontColor1;
    line-height: 22px;
  }
}
.width-min-255 {
  width: 255px;
}
</style>
