/*
 * @Version: 2.0
 * @Autor: YangKX
 * @Date: 2023-04-04 17:17:30
 * @LastEditors: YangKX
 * @LastEditTime: 2023-05-13 16:27:39
 */
import requesthhr from "@/utils/requesthhr";

const userApi = {
  // 门店 方案
  getCurrentList: "/api/v2/partner/manager/head/institution/current/list", // 当前登录人关联的门店机构列表
  getInstitutionList: "/api/v2/partner/manager/chain/archives/plan/institution/list", // 获取合伙人参与过方案的所有门店列表
  getPlanAll: "/api/v2/partner/manager/chain/plan/all", // 当前登录人关联的门店方案列表

  // 连锁 合伙人档案
  partnerArchivesList: "/api/v2/partner/manager/chain/archives/list", // 列表
  partnerArchivesDetail: "/api/v2/partner/manager/chain/archives/detail", // 详情
  partnerArchivesInviter: "/api/v2/partner/manager/chain/archives/inviter/list", // 转介绍人
  partnerArchivesAccDetail:
    "/api/v2/partner/manager/chain/archives/account/detail/list", // 合伙人钱包账户明细
  partnerArchivesDraft:
    "/api/v2/partner/manager/chain/archives/draft/income/list", // 待结算列表
  partnerArchivesSettled:
    "/api/v2/partner/manager/chain/archives/settled/income/list", // 已结算列表
  partnerArchivesBenefit:
    "/api/v2/partner/manager/chain/archives/history/benefit/list", // 归档-合伙人权益
  partnerArchivesPlan:
    "/api/v2/partner/manager/chain/archives/history/plan/list", // 归档-历史合伙人方案
  partnerArchivesInviterHead:
    "/api/v2/partner/manager/chain/archives/inviter/user/head", // 转介绍人详情佣金列表-头部信息
  partnerArchivesInviterList:
    "/api/v2/partner/manager/chain/archives/inviter/commission/order/list", // 转介绍人佣金订单列表
  // 新增修改
  partnerArchivesShopList: "/api/v2/partner/manager/chain/archives/auth/shop/list", // 获取门店列表（需要有正在进行中的方案且可申请）
  partnerPhoneCheck: "/api/v2/partner/manager/chain/archives/phone/check", // 手机号检查
  partnerArchivesAdd: "/api/v2/partner/manager/chain/archives/partner/add", // 新增合伙人接口
  partnerArchivesBankAdd: "/api/v2/partner/manager/chain/archives/partner/bankcard/add", // 新增银行卡
  partnerBalacneUpdate: "/api/v2/partner/manager/chain/archives/partner/balacne/update", // 修改合伙人账户余额
  partnerInviterAdd: "/api/v2/partner/manager/chain/archives/partner/inviter/add", // 添加转介绍人
  partnerInviterCheck: "/api/v2/partner/manager/chain/archives/partner/inviter/archiveno/check", // 添加转介绍人-校验客户档案编号是否存在

};

/**
 * @param parameter
 * @returns {*}
 */
export function partnerInviterCheck(parameter) {
  return requesthhr({
    url: userApi.partnerInviterCheck,
    method: "get",
    params: parameter,
  });
}
export function getInstitutionList(parameter) {
  return requesthhr({
    url: userApi.getInstitutionList,
    method: "get",
    params: parameter,
  });
}
export function partnerInviterAdd(parameter) {
  return requesthhr({
    url: userApi.partnerInviterAdd,
    method: "post",
    data: parameter,
  });
}
export function partnerBalacneUpdate(parameter) {
  return requesthhr({
    url: userApi.partnerBalacneUpdate,
    method: "post",
    data: parameter,
  });
}
export function partnerArchivesBankAdd(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesBankAdd,
    method: "post",
    data: parameter,
  });
}
export function partnerArchivesAdd(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesAdd,
    method: "post",
    data: parameter,
  });
}
export function partnerPhoneCheck(parameter) {
  return requesthhr({
    url: userApi.partnerPhoneCheck,
    method: "get",
    params: parameter,
  });
}
export function partnerArchivesShopList(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesShopList,
    method: "get",
    params: parameter,
  });
}
export function partnerArchivesInviterHead(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesInviterHead,
    method: "get",
    params: parameter,
  });
}
export function partnerArchivesInviterList(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesInviterList,
    method: "get",
    params: parameter,
  });
}

export function getPlanAll(parameter) {
  return requesthhr({
    url: userApi.getPlanAll,
    method: "get",
    params: parameter,
  });
}
export function getCurrentList(parameter) {
  return requesthhr({
    url: userApi.getCurrentList,
    method: "get",
    params: parameter,
  });
}
export function partnerArchivesList(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesList,
    method: "get",
    params: parameter,
  });
}
export function partnerArchivesDetail(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesDetail,
    method: "get",
    params: parameter,
  });
}
export function partnerArchivesInviter(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesInviter,
    method: "get",
    params: parameter,
  });
}

export function partnerArchivesAccDetail(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesAccDetail,
    method: "get",
    params: parameter,
  });
}
export function partnerArchivesDraft(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesDraft,
    method: "get",
    params: parameter,
  });
}

export function partnerArchivesSettled(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesSettled,
    method: "get",
    params: parameter,
  });
}
export function partnerArchivesBenefit(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesBenefit,
    method: "get",
    params: parameter,
  });
}

export function partnerArchivesPlan(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesPlan,
    method: "get",
    params: parameter,
  });
}
