<template>
  <div class="addBox">
    <h2 class="twoh2">
      <span class="tpointer"></span>
      <span class="ttxt mgr-12">合伙人归档档案</span>

    </h2>
    <div class="mgl-10 mgb-20 mgt-20">
      <h2 class="twoh1">
        <span class="tpointer"></span>
        <span class="ttxt">认购信息</span>
      </h2>
      <a-table :scroll="{x:'100%'}" class="mgb-12 mgt-10 detailsTable" @change="tableChangePlan" :pagination="table_plan.pagination" row-key="id" size="middle" :columns="config.detail.plan_list" :data-source="table_plan.tableData">
      </a-table>
      <h2 class="twoh2" style="justify-content:center">
        <!-- <span class="ttxt">查看方案</span> -->
        <a-select class="mgl-12" style="width: 230px;" optionFilterProp="label" showSearch allowClear @change="getInit" placeholder="全部方案">
          <a-select-option :value="undefined">全部方案</a-select-option>
          <a-select-option v-for="it in schemeList" :key="it.partner_plan_id" :value="it.partner_plan_id" :label="it.partner_plan_name">
            {{ it.partner_plan_name }}
          </a-select-option>
        </a-select>
      </h2>
      <h2 class="twoh1" style="align-items:baseline">
        <span class="tpointer"></span>
        <span class="ttxt">合伙人权益</span>
      </h2>
      <a-table :scroll="{x:'100%'}" class="mgb-12 mgt-10 detailsTable" :pagination="false" row-key="partner_account_id" size="middle" :columns="config.detail.introducer_list_his" :data-source="table_benefit.tableData">
        <template slot="_1" slot-scope="action, record">
          <img class="wh30Img" :src="record.image" v-if="record.image" @click="handlePreview(record,'image/*')">
          <span v-else>暂无图片</span>
        </template>
        <template slot="_2" slot-scope="action, record">
          <span class="theme-color cursor-pointer" @click="openDesc(record.desc)">查看详情</span>
        </template>
      </a-table>
      <a-row class="filter-wrap mgb-20 mgr-20">
        <a-col :span="24">
          <h2 class="twoh1">
            <span class="tpointer"></span>
            <span class="ttxt">已结算收益</span>
          </h2>
          <div class="card-bg mgt-20">
            <div>
              <div class="title">已结算佣金（元）</div>
              <div class="money theme-color">{{detailInfo.history_income?.settled_commission_amount || 0}}</div>
            </div>
            <div>
              <div class="title">已结算分红（元）</div>
              <div class="money theme-color">{{detailInfo.history_income?.settled_dividend_amount || 0}}</div>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-table :scroll="{x:'100%'}" class="mgb-12 mgt-10 detailsTable" @change="tableChanged" :pagination="table.pagination" :loading="table.loading" size="middle" :columns="config.detail.settle_accounts2" :data-source="table.tableData">
        <template slot="_shop">
          <div class="cursor-pointer">
            <a-dropdown :trigger="['click']">
              <span @click="e => e.preventDefault()">
                <i style="font-size:15px;color:#9EA6A4" class="meiye-icon meiye-mendian"></i>
                门店
                <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
              </span>
              <a-menu slot="overlay">
                <a-menu-item @click="query.institution_id = undefined,query.page=1,getList()">
                  全部门店
                </a-menu-item>
                <a-menu-item v-for="v in shopList" :key="v.institution_id" @click="query.institution_id = v.institution_id,query.page=1,getList()">
                  {{v.shop_name}}
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </template>
        <template slot="_time">
          <!-- <div class="cursor-pointer">
            <a-dropdown :trigger="['click']">
              <span @click="e => e.preventDefault()">
                <i style="font-size:15px;color:#9EA6A4" class="meiye-icon meiye-riqi"></i>
                订单时间
                <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
              </span>
              <a-menu slot="overlay">
                <a-range-picker allowClear format="YYYY-MM-DD" @change="setMonth" />
              </a-menu>
            </a-dropdown>
          </div> -->
          <div class="cursor-pointer">
            <a-popover :title="null" v-model="visibleTime" trigger="click" placement="bottomLeft">
              <template slot="content">
                <a-range-picker style="width:280px" allowClear format="YYYY-MM-DD" @change="setMonth" />
              </template>
              <span>
                <i style="font-size:15px;color:#9EA6A4" class="meiye-icon meiye-riqi"></i>
                订单时间
                <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
              </span>
            </a-popover>
          </div>
        </template>
        <template slot="_type">
          <div class="cursor-pointer">
            <a-dropdown :trigger="['click']">
              <span @click="e => e.preventDefault()">
                <i style="font-size:15px;color:#9EA6A4" class="meiye-icon  meiye-shouyi"></i>
                收益类型
                <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
              </span>
              <a-menu slot="overlay">
                <a-menu-item @click="query.income_type = 0,query.page=1,getList()">
                  全部类型
                </a-menu-item>
                <a-menu-item v-for="v in queryStatusList" @click="query.income_type = v.status,query.page=1,getList()">
                  {{v.label}}
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </template>
      </a-table>
      <h2 class="twoh1">
        <span class="tpointer"></span>
        <span class="ttxt">合伙人转介绍</span>
      </h2>
      <div class="total_balance"> <span class="mgr-20 span">转介绍人数</span> {{table_introducer.pagination.total}} <span style="font-size: 14px;font-weight: 500;color: #323F3E;margin-left:8px">人</span></div>
      <a-table :scroll="{x:'100%'}" class="mgb-12 mgt-10 detailsTable" @change="tableChange" :pagination="table_introducer.pagination" row-key="id" size="middle" :columns="config.detail.referrer_list" :data-source="table_introducer.tableData">
        <template slot="_client">
          <div class="cursor-pointer">
            <a-popover :title="null" trigger="click" placement="bottomLeft">
              <template slot="content">
                <a-input ref="getfocus" v-model="query_introducer.keywords" @change="getIntroducerList" style="width: 200px;" placeholder="搜索客户姓名/手机号">
                  <i slot="prefix" style="font-size:9px" class="meiye-icon meiye-sousuo"></i>
                </a-input>
              </template>
              <span>
                <i style="font-size:15px;color:#9EA6A4" class="meiye-icon  meiye-kehu"></i>
                客户
                <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
              </span>
            </a-popover>
          </div>
        </template>
        <template slot="_4" slot-scope="action, record">
          <span class="theme-color cursor-pointer" @click="checkFile(record)">{{record.nickname}} {{ record.mobile}}</span>
        </template>
        <template slot="_8" slot-scope="action, record">
          <a-button size="small" class="smallbtn" @click="openIntroducer(record)">查看</a-button>
        </template>
      </a-table>
    </div>
    <a-modal v-model="desc_model.visible" title="权益描述" :footer="null" @cancel="desc_model.visible=false" :width="700">
      <div class="desc_css" v-html="desc_model.conter">{{desc_model.conter}}</div>
    </a-modal>
    <a-modal v-model="user_model.visible" title="钱包明细" :footer="null" @cancel="user_model.visible=false" :width="950">
      <div id="product-base" data-anchor="product-base">
        <div class="detail-title mgl-10">
          <div class="y-flex">
            <a-avatar shape="square" style="width:50px;height:50px" class="img" :src="table_user.dataList.avatar" />
            <div>
              <div style="margin-bottom:4px">
                <span class="title" style="font-size: 14px;">{{table_user.dataList.name || '--'}} {{table_user.dataList.mobile || '--'}} </span>
                <span class="mgl-12" size="small" :class="table_user.dataList.plan?.status == 0 ? 'mrj-span-tag-green' : 'mrj-span-tag-red'">{{table_user.dataList.plan?.status == 0? '合伙中' : '已到期'}}</span>
              </div>
              <div class="text-sm">用户ID：{{table_user.dataList.uid || '--'}} | <span class="mgl-12">门店：{{table_user.dataList.shop_abbreviation || '--'}}</span></div>
            </div>
          </div>
          <a-button class="mgr-20 table-btn-clk" type="primary" @click="checkFile(table_user.dataList)">查看客户档案</a-button>
        </div>
        <a-row class="mgl-10">
          <a-col :span="12">
            <div class="item-r-l">
              <span class="lText">合伙人方案</span>
              <span class="rText">{{table_user.dataList.plan?.partner_plan_name || '--'}}</span>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="item-r-l">
              <span class="lText">录入时间</span>
              <span class="rText">{{table_user.dataList.plan?.create_at || '--'}}</span>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="item-r-l">
              <span class="lText">佣金订单数</span>
              <span class="rText">{{table_user.dataList.commission_orders || '--'}}</span>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="item-r-l">
              <span class="lText">录入人</span>
              <span class="rText">{{table_user.dataList.plan?.entry_admin_name || '--'}}</span>
            </div>
          </a-col>
        </a-row>
        <div class="mgl-10 mgb-20">
          <a-table :scroll="{x:'100%'}" @change="tableChangeUser" class="mgb-12 mgt-10 detailsTable" :pagination="table_user.pagination" row-key="sn" size="middle" :columns="config.detail.table_user" :data-source="table_user.tableData">
            <template slot="_0" slot-scope="action, record">
              <a-tooltip placement="top">
                <template slot="title">
                  {{record.sn || '--'}}
                </template>
                {{record.sn || '--'}}
              </a-tooltip>
            </template>
            <template slot="_2" slot-scope="action, record">
              <span v-if="record.status==1" class="theme-color">已结算</span>
              <span v-else-if="record.status==0">待结算</span>
              <span v-else-if="record.status==2">已废弃</span>
              <span v-else>--</span>
            </template>
            <template slot="_order">
              <div class="cursor-pointer">
                <a-popover :title="null" trigger="click" placement="bottomLeft">
                  <template slot="content">
                    <a-input ref="getfocus" v-model="query_user.order_no" @change="getInitHeadList" style="width: 200px;" placeholder="搜索佣金订单编号">
                      <i slot="prefix" style="font-size:9px" class="meiye-icon meiye-sousuo"></i>
                    </a-input>
                  </template>
                  <span>
                    <i style="font-size:15px;color:#9EA6A4" class="meiye-icon meiye-bianhao"></i>
                    佣金订单编号
                    <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
                  </span>
                </a-popover>
              </div>
            </template>
            <template slot="_8" slot-scope="action, record">
              <a-button size="small" class="smallbtn" @click="todetail(record)">详情</a-button>
            </template>
          </a-table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import config from '../config'
import moment from 'moment';
import { partnerArchivesBenefit, partnerArchivesSettled, partnerArchivesDetail, partnerArchivesInviter, partnerArchivesPlan, partnerArchivesInviterHead, partnerArchivesInviterList, getPlanSearchAll } from '@/api/partner'
export default {
  props: {
    shopList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      config,
      detailInfo: '',
      schemeList: [],
      visibleTime: false,
      queryStatusList: [
        {
          status: 1,
          label: "佣金",
        },
        {
          status: 2,
          label: "分红",
        },
      ], user_model: {
        visible: false,
      },
      table_user: {
        dataList: '',
        tableData: [],
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: false,
          showQuickJumper: false,
        },
      },
      query: {
        per_page: 10,
        page: 1,
        partner_id: undefined,
        page_type: 2, // 页面数据类型:1-当期数据;2-归档数据
        institution_id: undefined,
        income_type: 1,
        start_date: undefined,
        partner_plan_id: undefined,
        end_date: undefined,
      },
      query_introducer: {
        per_page: 10,
        page: 1,
        partner_id: '',
        partner_plan_id: undefined,
        type: 1,
        keywords: undefined
      },
      query_plan: {
        partner_id: undefined,
        partner_plan_id: undefined,
        per_page: 10,
        page: 1,
      },
      query_benefit: {
        partner_id: undefined,
        partner_plan_id: undefined,
      },
      table_benefit: {
        tableData: [],
      },
      table_introducer: {
        tableData: [],
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: false,
          showQuickJumper: false,
        },
      },
      table_plan: {
        tableData: [],
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: false,
          showQuickJumper: false,
        },
      },
      table: {
        tableData: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: false,
          showQuickJumper: false,
        },
      },
      desc_model: {
        visible: false,
        conter: ''
      },
      query_detail: {
        partner_id: undefined,
        partner_plan_id: undefined,
      },
      query_user: {
        partner_id: undefined,
        shop_id: undefined,
        partner_plan_id: undefined,
        user_id: undefined,
        order_no: undefined,
        per_page: 10,
        page: 1,
      },
    }
  },
  created() {
    let self = this
    const { id } = self.$route.query
    this.query_detail.partner_id = id;
    this.query_introducer.partner_id = id;
    this.query_benefit.partner_id = id;
    this.query_plan.partner_id = id;
    this.query_user.partner_id = id;
    this.query.partner_id = id;
    this.getDetail();
    this.getList();
    this.getIntroducerList();
    this.getArchivesBenefit();
    this.getArchivesPlan();
    getPlanSearchAll({ partner_id: id }).then(res => {
      console.log(res.data, "data");
      this.schemeList = res.data
    })
  },
  methods: {
    moment,
    handlePreview(src, type) {
      this.$utils.previewImgVideo({ type: type, response: { data: { file_url: src.image } } })
    },
    getInit(e) {
      console.log('e :>> ', e);
      this.query.partner_plan_id = e;
      this.query_detail.partner_plan_id = e;
      this.query_user.partner_plan_id = e;
      this.query_benefit.partner_plan_id = e;
      this.query_introducer.partner_plan_id = e;
      this.query_plan.partner_plan_id = e;
      this.getDetail();
      this.getList();
      this.getIntroducerList();
      this.getArchivesBenefit();
      this.getArchivesPlan();
    },
    getDetail() {
      partnerArchivesDetail(this.query_detail).then(res => {
        this.detailInfo = res.data;
      })
    },
    // 获取结算列表
    getList() {
      let self = this;
      self.table.selectedRowKeys = []
      self.table.loading = true;
      const params = {
        ...self.query,
      };
      return partnerArchivesSettled(params)
        .then((res) => {
          console.log(res.data, "data");
          const { data = [], total = 0 } = res.data;
          self.table.tableData = data;
          self.table.pagination = Object.assign(
            {},
            { pageSize: this.query.per_page, current: this.query.page },
            {
              total: total,
            }
          );
        })
        .finally((r) => {
          self.table.loading = false;
        });
    },
    setMonth(rec) {
      this.visibleTime = false;
      this.query.start_date = rec[0] ? rec[0].format('YYYY-MM-DD') : '';;
      this.query.end_date = rec[1] ? rec[1].format('YYYY-MM-DD') : '';;
      this.getList();
    },
    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination;
      this.query = Object.assign(this.query, {
        per_page: Number(pageSize),
        page: Number(current),
      });
      this.getList();
    },
    // 归档合伙人收益
    getArchivesBenefit() {
      let self = this
      return partnerArchivesBenefit(self.query_benefit).then(res => {
        console.log(res.data, "data");
        self.table_benefit.tableData = res.data
      })
    },
    tableChangePlan(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination;
      this.query_plan = Object.assign(this.query_plan, {
        per_page: Number(pageSize),
        page: Number(current),
      });
      this.getArchivesPlan();
    },
    getArchivesPlan() {
      let self = this
      return partnerArchivesPlan(self.query_plan).then(res => {
        console.log(res.data, "data");
        const { data = [], total = 0 } = res.data;
        self.table_plan.tableData = data
        self.table_plan.pagination = Object.assign(
          {},
          { pageSize: this.query_plan.per_page, current: this.query_plan.page },
          {
            total: total,
          }
        );
      })
    },
    // 合伙人转介绍人
    tableChange(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination;
      this.query_introducer = Object.assign(this.query_introducer, {
        per_page: Number(pageSize),
        page: Number(current),
      });
      this.getIntroducerList();
    },
    getIntroducerList() {
      let self = this
      return partnerArchivesInviter(self.query_introducer).then(res => {
        console.log(res.data, "data");
        const { data = [], total = 0 } = res.data;
        self.table_introducer.tableData = data
        self.table_introducer.pagination = Object.assign(
          {},
          { pageSize: this.query_introducer.per_page, current: this.query_introducer.page },
          {
            total: total,
          }
        );
      })
    },
    // 查看权益卡 描述
    openDesc(conter) {
      this.desc_model = {
        visible: true,
        conter: conter
      }
    },
    // 查看转介绍人
    // 查看转介绍人
    openIntroducer(item) {
      console.log('item :>> ', item);
      this.query_user.shop_id = item.shop_id;
      this.query_user.partner_plan_id = item.partner_plan_id;
      this.query_user.user_id = item.user_id;
      this.getInitHead();
      this.getInitHeadList();
      this.user_model.visible = true
    },
    getInitHead() {
      let self = this
      partnerArchivesInviterHead(self.query_user).then(res => {
        self.table_user.dataList = res.data;
      })
    },
    tableChangeUser(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination;
      this.query_user = Object.assign(this.query_user, {
        per_page: Number(pageSize),
        page: Number(current),
      });
      this.getInitHeadList();
    },
    getInitHeadList() {
      let self = this
      partnerArchivesInviterList(self.query_user).then(res => {
        console.log(res.data, "data");
        const { data = [], total = 0 } = res.data;
        self.table_user.tableData = data
        self.table_user.pagination = Object.assign(
          {},
          { pageSize: this.query_user.per_page, current: this.query_user.page },
          {
            total: total,
          }
        );
      })
    },
    todetail(e) {
      this.$emit('todetail', e)
    },
    // 合伙人收益查看
    lookIntroducer() { },
    // 查看客户档案
    checkFile(item) {
      console.log('item :>> ', item);
      // dispatch只接受对象作为参数
      window.microApp.dispatch({ user_id: item.user_id, url: '/user/usercenter/detail' })
    },
    // 查看明细
    openDetail() { },
    // 合伙人钱包>编辑
    operation() { },

  }
};
</script>

<style scoped lang="less">
.wh30Img {
  width: 42px;
  height: 32px;
  margin-right: 10px;
  max-height: 30px;
  box-shadow: 0px 2px 4px 0px rgba(204, 209, 228, 0.35);
  border-radius: 2px;
  border: 1px solid #a3a8ca;
}
.twoh3 {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  .tpointer {
    display: inline-block;
    width: 4px;
    height: 4px;
    background: @primaryColor;
    border-radius: 5px;
    margin-right: 5px;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    margin-right: 8px;
  }
}
.desc_css {
  max-height: 64vh;
  overflow: auto;
}
.history-top {
  text-align: center;
  height: 31px;
  line-height: 31px;
  background: #fff0e8;
  border: 1px solid #ffd8c3;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f47c5b;
  .text {
    font-size: 12px;
    color: #b7978e;
  }
}
.item-r-l {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  .rText {
    color: @fontColor1;
  }
  .lText {
    color: rgba(45, 56, 53, 0.8);
    width: 123px;
    display: block;
  }
}
.total_balance {
  display: flex;
  align-items: center;
  background: #f3f9f8;
  border-radius: 4px;
  font-size: 22px;
  padding-left: 24px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: @primaryColor;
  .span {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323f3e;
  }
}
.twoh2 {
  display: flex;
  margin-bottom: 7px;
  align-items: center;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 8px;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    line-height: 22px;
  }
  .tag-sm {
    height: 22px;
    background: #e6f5f4;
    border-radius: 11px;
    padding: 0 6px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(45, 56, 53, 0.8);
    line-height: 22px;
  }
}
.twoh1 {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 5px;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    margin-right: 8px;
  }
}

.y-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.detail-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .img {
    width: 88px;
    height: 88px;
    background: #cbe3e1;
    border-radius: 8px;
    margin-right: 18px;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
  }
  .text {
    font-size: 14px;
    font-weight: 400;
    color: #323f3e;
  }
  .text-sm {
    font-size: 14px;
    font-weight: 400;
    color: #778581;
  }
}
.card-bg {
  height: 150px;
  background: #f3f9f8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  .title {
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323f3e;
    line-height: 22px;
    margin-bottom: 15px;
    margin-left: 15px;
  }
  .money {
    height: 30px;
    font-size: 26px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    line-height: 30px;
  }
}
.shortcut-menu {
  width: 130px;
  :deep(.ant-anchor-wrapper) {
    background-color: transparent;
  }
}
/deep/.ant-table-middle
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th,
/deep/.ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th {
  padding: 1px 8px;
}
/deep/.detailsTable .ant-table-row > td:nth-child(1),
/deep/.detailsTable .ant-table-thead > tr:first-child > th:first-child {
  padding-left: 24px !important;
}
</style>
