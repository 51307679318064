<template>
  <div>
    <div
      class="page-list personnelfiles flex-1"
      v-if="showType=='index'"
    >
      <a-row class="filter-wrap">
        <span class="ant-input_1">
          <a-input
            v-model="query.keywords"
            style="width: 330px;"
            allowClear
            @change="query.page = 1,getList()"
            placeholder="请输入合伙人姓名/电话"
            class="mgr-20"
          >
            <i
              slot="prefix"
              class="meiye-icon meiye-sousuo"
            ></i>
          </a-input>
        </span>
        <!-- <a-button type="primary" @click="query.page = 1,getList()">查询</a-button>
        <a-button class="mgl-12" type="link" @click="onReset">重置</a-button> -->
      </a-row>
      <div class="mgb-12 mgt-12">
        <table-filter
          :flitList="flitList"
          :isAllLength="false"
          :isShowAll="true"
          @transferson="clearfliterSingle"
        ></table-filter>
      </div>
      <a-table
        :customRow="rowClick"
        class="table-manage flex-1"
        :scroll="{ x: '100%',y:$utils.getfullHeight(330)}"
        @change="tableChanged"
        :pagination="table.pagination"
        row-key="partner_id"
        :loading="table.loading"
        :columns="config.customer.columns"
        :data-source="table.tableData"
      >
        <template slot="_shop">
          <div class="cursor-pointer">
            <a-popover
              :title="null"
              v-model="visibleInstitution"
              trigger="click"
              placement="bottomLeft"
            >
              <template slot="content">
                <a-select
                  class="width-min-164"
                  optionFilterProp="label"
                  showSearch
                  allowClear
                  v-model="query.institution_id"
                  @change="visibleInstitution=false,query.page = 1,getList()"
                  placeholder="全部门店"
                >
                  <a-select-option
                    v-for="it in shopList"
                    :key="it.id"
                    :value="it.id"
                    :label="it.name"
                  >
                    {{ it.name }}
                  </a-select-option>
                </a-select>
              </template>
              <span>
                <i
                  style="font-size:15px;color:#9EA6A4"
                  class="meiye-icon meiye-mendian"
                ></i>
                门店
                <i
                  style="font-size:9px"
                  class="meiye-icon meiye-xiala"
                ></i>
              </span>
            </a-popover>
          </div>
        </template>
        <template slot="_status">
          <div class="cursor-pointer">
            <a-dropdown :trigger="['click']" overlayClassName="mydropdown">
              <span @click="e => e.preventDefault()">
                <i
                  style="font-size:15px;color:#9EA6A4"
                  class="meiye-icon  meiye-zhuangtai"
                ></i>
                状态
                <i
                  style="font-size:9px"
                  class="meiye-icon meiye-xiala"
                ></i>
              </span>
              <a-menu
                slot="overlay"
                :default-selected-keys="['1']"
                :selected-keys="[query.status]"
              >
                <a-menu-item
                  v-for="v in queryStatusList"
                  @click="query.page = 1,query.status = v.status,getList()"
                  :key="v.status"
                  :value="v.status"
                >
                  {{v.label}}
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </template>
        <template
          slot="_3"
          slot-scope="action, record"
        >
          <a-popover
            :title="null"
            overlayClassName="overlayClassName"
            trigger="hover"
            placement="topLeft"
          >
            <template slot="content">
              <div style="width:760px">
                <a-row>
                  <a-col :span="6">门店</a-col>
                  <a-col :span="3">认购金</a-col>
                  <a-col :span="6">时间</a-col>
                  <a-col :span="6">合伙人方案</a-col>
                  <a-col :span="3">状态</a-col>
                </a-row>
                <a-row
                  class="mgt-12"
                  v-for="v in record.detail"
                  :key="v.partner_id"
                >
                  <a-col :span="6">{{v.shop_name}}</a-col>
                  <a-col :span="3">{{v.subscription_fee_premium}}</a-col>
                  <a-col :span="6">{{v.start_time}}</a-col>
                  <a-col :span="6">{{v.partner_plan_name}}</a-col>
                  <a-col :span="3">{{v.status_text}}</a-col>
                </a-row>
              </div>
            </template>
            {{record.summary_shop_name}}
          </a-popover>
        </template>
        <template
          slot="_4"
          slot-scope="action, record"
        >
          <a-popover
            :title="null"
            overlayClassName="overlayClassName"
            trigger="hover"
            placement="topLeft"
          >
            <template slot="content">
              <div style="width:760px">
                <a-row>
                  <a-col :span="6">门店</a-col>
                  <a-col :span="3">认购金</a-col>
                  <a-col :span="6">时间</a-col>
                  <a-col :span="6">合伙人方案</a-col>
                  <a-col :span="3">状态</a-col>
                </a-row>
                <a-row
                  class="mgt-12"
                  v-for="v in record.detail"
                  :key="v.partner_id"
                >
                  <a-col :span="6">{{v.shop_name}}</a-col>
                  <a-col :span="3">{{v.subscription_fee_premium}}</a-col>
                  <a-col :span="6">{{v.start_time}}</a-col>
                  <a-col :span="6">{{v.partner_plan_name}}</a-col>
                  <a-col :span="3">{{v.status_text}}</a-col>
                </a-row>
              </div>
            </template>
            {{record.summary_amount || '--'}}
          </a-popover>
        </template>
        <template
          slot="_5"
          slot-scope="action, record"
        >
          <a-popover
            :title="null"
            overlayClassName="overlayClassName"
            trigger="hover"
            placement="topLeft"
          >
            <template slot="content">
              <div style="width:760px">
                <a-row>
                  <a-col :span="6">门店</a-col>
                  <a-col :span="3">认购金</a-col>
                  <a-col :span="6">时间</a-col>
                  <a-col :span="6">合伙人方案</a-col>
                  <a-col :span="3">状态</a-col>
                </a-row>
                <a-row
                  class="mgt-12"
                  v-for="v in record.detail"
                  :key="v.partner_id"
                >
                  <a-col :span="6">{{v.shop_name}}</a-col>
                  <a-col :span="3">{{v.subscription_fee_premium}}</a-col>
                  <a-col :span="6">{{v.start_time}}</a-col>
                  <a-col :span="6">{{v.partner_plan_name}}</a-col>
                  <a-col :span="3">{{v.status_text}}</a-col>
                </a-row>
              </div>
            </template>
            {{record.summary_add_time || '--'}}
          </a-popover>
        </template>
        <template
          slot="_6"
          slot-scope="action, record"
        >
          <a-popover
            :title="null"
            overlayClassName="overlayClassName"
            trigger="hover"
            placement="top"
          >
            <template slot="content">
              <div style="width:760px">
                <a-row>
                  <a-col :span="6">门店</a-col>
                  <a-col :span="3">认购金</a-col>
                  <a-col :span="6">时间</a-col>
                  <a-col :span="6">合伙人方案</a-col>
                  <a-col :span="3">状态</a-col>
                </a-row>
                <a-row
                  class="mgt-12"
                  v-for="v in record.detail"
                  :key="v.partner_id"
                >
                  <a-col :span="6">{{v.shop_name}}</a-col>
                  <a-col :span="3">{{v.subscription_fee_premium}}</a-col>
                  <a-col :span="6">{{v.start_time}}</a-col>
                  <a-col :span="6">{{v.partner_plan_name}}</a-col>
                  <a-col :span="3">{{v.status_text}}</a-col>
                </a-row>
              </div>
            </template>
            {{setPlan(record.detail)}}
          </a-popover>
          <!-- <a-tooltip placement="top">
            <template slot="title">
              {{setPlan(record.detail)}}
            </template>
            {{setPlan(record.detail)}}
          </a-tooltip> -->
        </template>
        <template
          slot="_7"
          slot-scope="action, record"
        >
          <span
            class="theme-color"
            v-if="record.status == 0"
          >合伙中</span>
          <span v-else-if="record.status == 1">已到期</span>
          <span v-else>已停用</span>
        </template>
        <template
          slot="_8"
          slot-scope="action, record"
        >
          <a-button
            size="small"
            class="mgr-12 smallbtn"
            @click.stop="toDetail(record,1)"
          >详情</a-button>
        </template>
      </a-table>
    </div>
    <template v-if="showType=='add'">
      <Add @onCancel="onCancel"></Add>
    </template>
    <template v-if="showType=='detail'">
      <Detail @onCancel="onCancel"></Detail>
    </template>
  </div>

</template>
<script> 

import Add from './components/add.vue'
import Detail from './components/detail.vue'
import config from './config'
import { partnerArchivesList, getCurrentList } from '@/api/partner'
import TableFilter from '@/components/intimate/tableFilter/index'

export default {
  components: { Add, Detail, TableFilter },
  data() {
    return {
      config,
      showType: 'index',
      visibleInstitution: false,
      visibleStatus: false,
      shopList: [],
      flitList: [
        { key: '门店', value: [], paramas: 'institution_id' },
        { key: '状态', value: [], paramas: 'status' },
      ],
      queryStatusList: [
        {
          status: 1,
          label: "合伙中",
        },
        {
          status: 2,
          label: "已到期",
        },
      ],
      query: {
        per_page: 10,
        page: 1,
        institution_id: undefined,
        keywords: undefined,
        status: 0,
      },
      table: {
        tableData: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      },
      statusDefault: undefined,
    }
  },
  created() {
    this.getList()
    // 获取门店
    getCurrentList({ brand_id: localStorage.getItem('global_brand_id') }).then(res => {
      this.shopList = res.data;
      console.log('this.shopList :>> ', this.shopList);
    })
  },
  watch: {
    'query.institution_id': {
      handler(val) {
        console.log('val :>> ', val);
        if(!val){
          this.flitList[0].value = []
        }
        let str = this.shopList.find(v => v.id == val);
        if (str) {
          this.flitList[0].value = [str.name]
        }
      }
    },
    'query.status': {
      handler(val) {
        console.log('val :>> ', val);
        if(!val){
          this.flitList[1].value = []
        }
        let str = this.queryStatusList.find(v => v.status == val);
        if (str) {
          this.flitList[1].value = [str.label]
        }
      }
    }
  },
  methods: {
    clearfliterSingle(e) {
      console.log(e);
      if (e) {
        // 单个清除 根据业务 去除 
        if (e.paramas == 'status') {
          this.query.status = 0
        } else {
          this.query[e.paramas] = undefined
        }
        this.flitList.forEach(el => {
          if (el.paramas == e.paramas) {
            el.value = []
          }
        })
        this.getList()
      } else {
        // 全部清除
        this.clearfliterAll()
      }
    },
    // 清除 类别  分类 品牌  状态 恢复 初始值
    clearfliterAll() {
      // 清除分类
      this.flitList = [
        { key: '门店', value: [], paramas: 'institution_id' },
        { key: '状态', value: [], paramas: 'status' },
      ]
      this.onReset()
    },
    rowClick(record, index) {
      return {
        on: {
          click: () => {
            this.toDetail(record, 1)
          }
        }
      }
    },
    onReset() {
      this.query.per_page = 10;
      this.query.page = 1;
      this.query.institution_id = undefined;
      this.query.keywords = undefined;
      this.query.status = 0;
      this.getList()
    },
    getfullHeight(val) {
      console.log(document.body.offsetHeight - val);
      return document.body.offsetHeight - val - 159 + 16
    },
    setPlan(arr) {
      if (arr.length) {
        var idsStr = arr.map(val => { return val.partner_plan_name }).join(",")
        return idsStr
      } else {
        return '--'
      }
    },
    // 获取列表
    getList() {
      let self = this
      self.table.loading = true
      return partnerArchivesList(self.query).then(res => {
        console.log(res.data, "data");
        const { data = [], total = 0 } = res.data
        self.table.tableData = data
        self.table.pagination = Object.assign({}, { pageSize: this.query.per_page, current: this.query.page }, {
          total: total
        })
      }).finally(r => {
        self.table.loading = false
      })
    },
    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination
      const { field, order } = sorter
      this.query = Object.assign(this.query, { per_page: Number(pageSize), page: Number(current) })
      this.getList()
    },

    onCancel() { this.showType = 'index'; this.getList() },
    //查看详情
    toDetail(e, i) {
      if (!e.partner_id) return
      this.$router.push({ path: 'partnerMenuFile', query: { type: 3, id: e.partner_id } })
      this.showType = 'detail';
    },
    // 添加
    addUser() {
      this.showType = 'add';
      this.$router.push({ path: 'partnerMenuFile' })
    },
  },

}
</script>
<style lang="less" scoped>
.baseColor_copy {
  color: @primaryColor;
}
.page-list {
  background: #fff;
  // margin: 18px;
  border-radius: 2px;
  min-height: calc(100vh - 88px);
}
</style>
<style  lang="less">
.overlayClassName {
  .ant-popover-inner {
    background: rgba(0, 0, 0, 0.9);
    box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05),
      0px 8px 10px 1px rgba(0, 0, 0, 0.06), 0px 5px 5px -3px rgba(0, 0, 0, 0.1);
    border: 0;
  }
  .ant-popover-inner-content {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
  }
  .ant-popover-content > .ant-popover-arrow {
    border-top-color: transparent;
    border-right-color: #000;
    border-bottom-color: #000;
  }
}
</style>