export default {
  tabs: [
    {
      id: "product-base",
      label: "基础信息",
    },
    {
      id: "product-detail",
      label: "合伙人资产",
    },
    {
      id: "product-user",
      label: "合伙人转介绍",
    },
    {
      id: "product-file",
      label: "归档档案",
    },
  ],
  customer: {
    columns: [
      {
        title: "合伙人档案ID",
        dataIndex: "partner_uuid",
        key: "_0",
        ellipsis: true,
        width: 140,
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "姓名",
        dataIndex: "name",
        key: "_1",
        ellipsis: true,
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "电话",
        dataIndex: "phone",
        key: "_2",
        ellipsis: true,
        scopedSlots: { customRender: "_2" },
      },
      {
        slots: { title: "_shop" },
        dataIndex: "summary_shop_name",
        key: "_3",
        ellipsis: true,
        scopedSlots: { customRender: "_3" },
      },
      {
        title: "认购金",
        dataIndex: "summary_amount",
        key: "_4",
        ellipsis: true,
        scopedSlots: { customRender: "_4" },
      },
      {
        title: "时间",
        dataIndex: "summary_add_time",
        key: "_5",
        ellipsis: true,
        scopedSlots: { customRender: "_5" },
      },
      {
        title: "合伙人方案",
        dataIndex: "name",
        key: "_6",
        ellipsis: true,
        scopedSlots: { customRender: "_6" },
      },
      {
        slots: { title: "_status" },
        dataIndex: "status",
        key: "_7",
        ellipsis: true,
        scopedSlots: { customRender: "_7" },
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "_8",
        width:100,
        scopedSlots: { customRender: "_8" },
      },
    ],
  },
  detail: {
    bank_card: [
      {
        title: "门店",
        dataIndex: "shop_name",
        ellipsis: true,
        key: "_0",
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "银行卡号",
        dataIndex: "bank",
        customRender: (str) => str?.bank_card_no || "--",
        ellipsis: true,
        key: "_1",
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "开户行",
        dataIndex: "bank",
        customRender: (str) => str?.bank_name || "--",
        ellipsis: true,
        key: "_2",
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "持卡人",
        dataIndex: "bank",
        customRender: (str) => str?.name || "--",
        ellipsis: true,
        key: "_3",
        scopedSlots: { customRender: "_3" },
      },
      // {
      //   title: "来源",
      //   dataIndex: "subscribed_shares",
      //   key: "_4",
      //   scopedSlots: { customRender: "_4" },
      // },
      {
        title: "录入人",
        dataIndex: "admin_name",
        ellipsis: true,
        key: "_5",
        scopedSlots: { customRender: "_5" },
      },
      {
        title: "录入时间",
        dataIndex: "created_at",
        ellipsis: true,
        key: "_6",
        scopedSlots: { customRender: "_6" },
      },
    ],
    plan_list: [
      {
        title: "门店",
        dataIndex: "shop_name",
        ellipsis: true,
        key: "_0",
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "方案",
        dataIndex: "partner_plan_name",
        ellipsis: true,
        key: "_1",
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "方案效期",
        ellipsis: true,
        dataIndex: "identify_end_date",
        key: "_2",
        customRender: (str) => str || "--",
        width: 180,
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "认购金",
        dataIndex: "subscription_fee",
        key: "_3",
        width: 110,
        customRender: (str) => "¥" + str || "0",
        scopedSlots: { customRender: "_3" },
      },
      {
        title: "认购金溢价",
        dataIndex: "subscription_fee_premium",
        key: "_4",
        width: 100,
        customRender: (str) => "¥" + str || "0",
        scopedSlots: { customRender: "_4" },
      },
      {
        title: "转介绍人数",
        dataIndex: "inviter_num",
        key: "_10",
        width: 100,
        customRender: (str) => str || "0",
        scopedSlots: { customRender: "_10" },
      },
      {
        title: "收益",
        dataIndex: "income_amount",
        key: "_11",
        width: 100,
        customRender: (str) => "¥" + str || "0",
        scopedSlots: { customRender: "_11" },
      },
      {
        title: "钱包余额",
        dataIndex: "balance",
        key: "_5",
        width: 100,
        customRender: (str) => "¥" + str || "0",
        scopedSlots: { customRender: "_5" },
      },

      {
        title: "消费股",
        dataIndex: "subscribed_shares",
        key: "_9",
        width: 100,
        customRender: (str) => (str || "0") + "股",
        scopedSlots: { customRender: "_9" },
      },
      {
        title: "录入人",
        dataIndex: "entry_admin_name",
        ellipsis: true,
        width: 100,
        key: "_7",
        scopedSlots: { customRender: "_7" },
      },
      {
        title: "录入时间",
        dataIndex: "created_at",
        ellipsis: true,
        key: "_6",
        scopedSlots: { customRender: "_6" },
      },
    ],
    money_list: [
      {
        title: "门店",
        dataIndex: "shop_name",
        ellipsis: true,
        key: "_0",
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "方案",
        dataIndex: "partner_plan_name",
        ellipsis: true,
        key: "_1",
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "方案效期",
        ellipsis: true,
        dataIndex: "identify_end_date",
        key: "_2",
        customRender: (str) => str || "--",
        width: 180,
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "认购金",
        dataIndex: "subscription_fee",
        key: "_3",
        customRender: (str) => "¥" + str || "0",
        scopedSlots: { customRender: "_3" },
      },
      {
        title: "认购金溢价",
        dataIndex: "subscription_fee_premium",
        key: "_4",
        customRender: (str) => "¥" + str || "0",
        scopedSlots: { customRender: "_4" },
      },
      {
        title: "钱包余额",
        dataIndex: "balance",
        key: "_5",
        customRender: (str) => "¥" + str || "0",
        scopedSlots: { customRender: "_5" },
      },
      {
        title: "消费股",
        dataIndex: "subscribed_shares",
        key: "_9",
        customRender: (str) => (str || "0") + "股",
        scopedSlots: { customRender: "_9" },
      },
      {
        title: "录入人",
        dataIndex: "entry_admin_name",
        ellipsis: true,
        key: "_7",
        scopedSlots: { customRender: "_7" },
      },
      {
        title: "录入时间",
        dataIndex: "created_at",
        ellipsis: true,
        key: "_6",
        scopedSlots: { customRender: "_6" },
      },
      // {
      //   title: "操作",
      //   dataIndex: "actions",
      //   width: 90,
      //   key: "_8",
      //   scopedSlots: { customRender: "_8" },
      // },
    ],
    table_user: [
      {
        slots: { title: "_order" },
        dataIndex: "sn",
        key: "_0",
        ellipsis: true,
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "收款金额",
        dataIndex: "commission_amount",
        ellipsis: true,
        width: 100,
        customRender: (str) => "¥" + str || "0",
        key: "_3",
        scopedSlots: { customRender: "_3" },
      },
      {
        title: "佣金",
        dataIndex: "total_amount",
        customRender: (str) => "¥" + str || "0",
        ellipsis: true,
        width: 100,
        key: "_4",
        scopedSlots: { customRender: "_4" },
      },
      {
        title: "收款时间",
        dataIndex: "pay_time",
        width: 160,
        key: "_5",
        scopedSlots: { customRender: "_5" },
      },
      {
        title: "结算时间",
        dataIndex: "settlement_time",
        width: 160,
        customRender: (str) => str || "--",
        key: "_1",
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "佣金状态",
        dataIndex: "status",
        width: 100,
        key: "_2",
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "操作",
        dataIndex: "actions",
        width: 90,
        key: "_8",
        scopedSlots: { customRender: "_8" },
      },
    ],
    plan_detail_list: [
      {
        slots: { title: "_shop" },
        dataIndex: "shop_name",
        key: "_0",
        ellipsis: true,
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "合伙人方案",
        dataIndex: "partner_plan_name",
        ellipsis: true,
        key: "_1",
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "收支类型",
        dataIndex: "transaction_type_str",
        ellipsis: true,
        key: "_2",
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "交易金额",
        dataIndex: "amount",
        ellipsis: true,
        customRender: (str) => (str || "0") + "元",
        key: "_3",
        scopedSlots: { customRender: "_3" },
      },
      {
        title: "钱包余额",
        dataIndex: "after_amount",
        ellipsis: true,
        customRender: (str) => (str || "0") + "元",
        key: "_4",
        scopedSlots: { customRender: "_4" },
      },
      {
        slots: { title: "_time" },
        dataIndex: "transaction_time",
        width: 160,
        key: "_5",
        scopedSlots: { customRender: "_5" },
      },
      {
        title: "备注",
        dataIndex: "description",
        ellipsis: true,
        width: 160,
        key: "_6",
        scopedSlots: { customRender: "_6" },
      },
    ],
    settle_accounts: [
      {
        slots: { title: "_shop" },
        dataIndex: "shop_name",
        key: "_0",
        ellipsis: true,
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "方案",
        dataIndex: "partner_plan_name",
        ellipsis: true,
        key: "_1",
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "订单编号",
        dataIndex: "sn",
        ellipsis: true,
        key: "_2",
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "预计收益",
        dataIndex: "amount",
        ellipsis: true,
        key: "_3",
        customRender: (str) => "¥" + str || "0",
        scopedSlots: { customRender: "_3" },
      },
      {
        slots: { title: "_type" },
        dataIndex: "type",
        key: "_4",
        customRender: (str) => (str == 1 ? "佣金" : "分红"),
        scopedSlots: { customRender: "_4" },
      },
      {
        slots: { title: "_time" },
        dataIndex: "created_at",
        key: "_5",
        scopedSlots: { customRender: "_5" },
      },
    ],
    settle_accounts2: [
      {
        slots: { title: "_shop" },
        dataIndex: "shop_name",
        key: "_0",
        ellipsis: true,
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "方案",
        dataIndex: "partner_plan_name",
        ellipsis: true,
        key: "_1",
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "订单编号",
        dataIndex: "transaction_sn",
        ellipsis: true,
        customRender: (str) => str || "--",
        key: "_2",
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "收益金额",
        dataIndex: "amount",
        ellipsis: true,
        width: 130,
        key: "_3",
        customRender: (str) => "¥" + str || "0",
        scopedSlots: { customRender: "_3" },
      },
      {
        slots: { title: "_type" },
        dataIndex: "type",
        width: 130,
        key: "_4",
        customRender: (str) => (str == 1 ? "佣金" : "分红"),
        scopedSlots: { customRender: "_4" },
      },
      {
        slots: { title: "_time" },
        dataIndex: "created_at",
        key: "_5",
        scopedSlots: { customRender: "_5" },
      },
      {
        title: "收益单号",
        dataIndex: "order_no",
        customRender: (str) => str || "--",
        ellipsis: true,
        key: "_6",
        scopedSlots: { customRender: "_6" },
      },
    ],
    introducer_list: [
      {
        title: "权益名称",
        dataIndex: "name",
        customRender: (str) => str || "--",
        ellipsis: true,
        key: "_0",
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "权益图",
        dataIndex: "image",
        key: "_1",
        scopedSlots: { customRender: "_1" },
      },

      {
        title: "权益规格",
        dataIndex: "specification",
        customRender: (str) => str || "--",
        ellipsis: true,
        key: "_3",
        scopedSlots: { customRender: "_3" },
      },
      {
        title: "权益单位",
        dataIndex: "unit",
        customRender: (str) => str || "--",
        key: "_4",
        scopedSlots: { customRender: "_4" },
      },
      {
        title: "门店",
        dataIndex: "shop_name",
        key: "_5",
        scopedSlots: { customRender: "_5" },
      },
      {
        title: "权益次数",
        dataIndex: "count",
        customRender: (str) => str || "--",
        key: "_6",
        scopedSlots: { customRender: "_6" },
      },
      {
        title: "权益详情",
        dataIndex: "desc",
        ellipsis: true,
        key: "_2",
        scopedSlots: { customRender: "_2" },
      },
      // {
      //   title: "操作",
      //   width: 100,
      //   key: "_7",
      //   scopedSlots: { customRender: "_7" },
      // },
    ],
    introducer_list_his: [
      {
        title: "权益名称",
        dataIndex: "name",
        customRender: (str) => str || "--",
        ellipsis: true,
        key: "_0",
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "权益图",
        dataIndex: "image",
        key: "_1",
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "权益规格",
        dataIndex: "specification",
        customRender: (str) => str || "--",
        ellipsis: true,
        key: "_3",
        scopedSlots: { customRender: "_3" },
      },
      {
        title: "权益单位",
        dataIndex: "unit",
        customRender: (str) => str || "--",
        key: "_4",
        scopedSlots: { customRender: "_4" },
      },
      {
        title: "门店",
        dataIndex: "shop_name",
        key: "_5",
        scopedSlots: { customRender: "_5" },
      },
      {
        title: "权益次数",
        dataIndex: "count",
        customRender: (str) => str || "--",
        key: "_6",
        scopedSlots: { customRender: "_6" },
      },
      {
        title: "权益详情",
        dataIndex: "desc",
        ellipsis: true,
        key: "_2",
        scopedSlots: { customRender: "_2" },
      },
    ],
    referrer_list: [
      {
        title: "UID",
        dataIndex: "uid",
        ellipsis: true,
        width: 100,
        key: "_2",
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "门店",
        dataIndex: "shop_name",
        ellipsis: true,
        key: "_0",
        scopedSlots: { customRender: "_0" },
      },
      {
        slots: { title: "_client" },
        dataIndex: "nickname",
        width: 160,
        key: "_4",
        scopedSlots: { customRender: "_4" },
      },
      {
        title: "佣金订单数",
        dataIndex: "order_num",
        ellipsis: true,
        key: "_1",
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "消费总额",
        dataIndex: "total_amount",
        key: "_3",
        customRender: (str) => "¥" + str || "0",
        scopedSlots: { customRender: "_3" },
      },
      {
        title: "已结算佣金",
        dataIndex: "settlement_amount",
        key: "_7",
        customRender: (str) => "¥" + str || "0",
        scopedSlots: { customRender: "_7" },
      },
      {
        title: "待结算佣金",
        dataIndex: "expected_amount",
        key: "_5",
        customRender: (str) => "¥" + str || "0",
        scopedSlots: { customRender: "_5" },
      },
      {
        title: "录入时间",
        dataIndex: "invitation_time",
        ellipsis: true,
        key: "_6",
        scopedSlots: { customRender: "_6" },
      },
      {
        title: "操作",
        dataIndex: "actions",
        width: 90,
        key: "_8",
        scopedSlots: { customRender: "_8" },
      },
    ],
  },
};
