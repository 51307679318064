<!--
 * @Version: 2.0
 * @Autor: YangKX
 * @Date: 2023-04-11 10:26:28
 * @LastEditors: YangKX
 * @LastEditTime: 2023-05-22 14:23:50
-->
<template>
  <div class="addBox">
    <template v-if="showType">
      <!--  面包屑  -->
      <div class="mrj-layout-tabs-custom flex align-item-center" style="z-index:1;position: relative;">
        <div @click="changeRoute" class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer">
          <div type="left" class="leftarrow" :class="imgflag?'leftarrow_hover':''" @mouseenter="imgflag=true" @mouseleave="imgflag=false">
            <i class="meiye-icon meiye-fanghui"></i>
          </div>
          <span @mouseenter="imgflag=true" @mouseleave="imgflag=false">{{ $route.meta.title }}</span>
        </div>
        <span class="mrj-layout-tabs-custom-fenge">/</span>
        <span class="mrj-layout-tabs-custom-inner-page">详情</span>
      </div>

      <div class="flex flex-column">
        <a-layout class="height100 flex">
          <TagsSider :tabs="config.tabs" v-model="activeKey" />
          <a-layout-content class="main-ctn-2" id="mrj-scroll-main" @scroll="handleWrapScroll">
            <div id="product-base" data-anchor="product-base">
              <div class="detail-title">
                <div class="y-flex">
                  <a-avatar shape="square" class="img" :src="detailInfo.info?.avatar" />
                  <div>
                    <div style="margin-bottom:4px">
                      <span class="title">{{detailInfo.info?.name || '--'}} </span>
                      <img style="height:14px;margin-bottom: 4px;margin-left:6px" v-if="detailInfo.info?.status == 0" src="@/assets/images/hehuozhong.png" alt="合伙中">
                      <img style="height:14px;margin-bottom: 4px;margin-left:6px" v-else src="@/assets/images/yiguoqi.png" alt="已过期">
                    </div>
                    <div class="text mgb-12"><i class="meiye-icon meiye-zhanghao" style="color:#819190"></i>{{detailInfo.info?.nickname || '--'}} <a-divider type="vertical" style="height: 9px; background-color: #DDE8E7;" /> <span><i class="meiye-icon meiye-shouji" style="color:#819190"></i>{{detailInfo.info?.phone || '--'}}</span></div>
                    <div class="text-sm">合伙人档案ID：{{detailInfo.info?.partner_uuid || '--'}}
                      <a-divider type="vertical" style="height: 9px; background-color: #DDE8E7;" />
                      <span>消费股：{{detailInfo.info?.total_subscribed_shares || '0'}}股</span>
                    </div>
                  </div>
                </div>
                <a-button class="mgr-20 table-btn-clk" type="primary" @click="checkFile(detailInfo.info)">查看客户档案</a-button>
              </div>
              <a-divider style="background:D6E3E0" />
              <h2 class="twoh2">
                <span class="tpointer"></span>
                <span class="ttxt">合伙人银行卡</span>
                <span class="tag-sm ">使用中 <span class="theme-color">{{detailInfo.inuse_cards || 0}}</span> 张</span>
              </h2>
              <div class="mgl-10 mgb-20">
                <a-table :scroll="{x:'100%'}" class="mgb-12 mgt-10 detailsTable" :pagination="false" row-key="partner_with_plan_id" size="middle" :columns="config.detail.bank_card" :data-source="detailInfo.bank_cards">
                </a-table>
              </div>
            </div>
            <div id="product-detail" data-anchor="product-detail">
              <h2 class="twoh2">
                <span class="tpointer"></span>
                <span class="ttxt">合伙人资产</span>
              </h2>
              <div class="mgl-10 mgb-20 mgt-20">
                <h2 class="twoh1">
                  <span class="tpointer"></span>
                  <span class="ttxt">合伙人钱包</span>
                  <a-button class="smallbtn" @click="openDetail()">明细</a-button>
                </h2>
                <div class="total_balance"> <span class="mgr-20 span">余额</span> ¥{{detailInfo.total_balance||0}}</div>
                <a-table :scroll="{x:'100%'}" class="mgb-12 mgt-10 detailsTable" :pagination="false" row-key="partner_account_id" size="middle" :columns="config.detail.money_list" :data-source="detailInfo.partner_accounts">
                  <template slot="_8" slot-scope="action, record">
                    <!-- <a-button size="small" class="mgr-12 smallbtn" @click="operation(1,record)">编辑</a-button>
                  <a-button size="small" class="mgr-12 smallbtn" @click="operation(2,record)">停用</a-button> -->
                    <a-button size="small" class="smallbtn" @click="operation(3,record)">查看</a-button>
                  </template>
                </a-table>
              </div>
              <h2 class="twoh2">
                <span class="tpointer"></span>
                <span class="ttxt">合伙人收益</span>
              </h2>
              <div class="mgl-10 mgb-20 mgt-20 mgr-20">
                <a-row :gutter="20" class="filter-wrap mgb-20" type="flex" justify="space-between">
                  <a-col :span="12">
                    <h2 class="twoh3">
                      <span class="tpointer"></span>
                      <span class="ttxt">待结算收益</span>
                    </h2>
                    <div class="card-bg mgt-20">
                      <div>
                        <div class="title">待结算佣金（元）</div>
                        <div class="money theme-color">{{detailInfo.current_income?.draft_commission_amount}}</div>
                      </div>
                      <div>
                        <div class="title">待结算分红（元）</div>
                        <div class="money theme-color">{{detailInfo.current_income?.draft_dividend_amount}}</div>
                      </div>
                    </div>
                  </a-col>
                  <a-col :span="12">
                    <h2 class="twoh3">
                      <span class="tpointer"></span>
                      <span class="ttxt">已结算收益</span>
                    </h2>
                    <div class="card-bg mgt-20">
                      <div>
                        <div class="title">已结算佣金（元）</div>
                        <div class="money theme-color">{{detailInfo.current_income?.settled_commission_amount || 0}}</div>
                      </div>
                      <div>
                        <div class="title">已结算分红（元）</div>
                        <div class="money theme-color">{{detailInfo.current_income?.settled_dividend_amount || 0}}</div>
                      </div>
                    </div>
                  </a-col>
                  <a-col :span="1"></a-col>
                </a-row>
                <a-radio-group v-model="modeRadio" class="lable_radiogroup">
                  <a-radio-button :value="1">
                    待结算收益
                  </a-radio-button>
                  <a-radio-button :value="2">
                    已结算收益
                  </a-radio-button>
                </a-radio-group>
                <div class="mgb-12 mgt-12">
                  <table-filter :flitList="flitList" :isAllLength="false" :paddingTrue="true" :isShowAll="true" @transferson="clearfliterSingle"></table-filter>
                </div>
                <a-table :scroll="{x:'100%'}" class="mgb-12 mgt-20 detailsTable" @change="tableChanged" :pagination="table.pagination" :loading="table.loading" size="middle" :columns="modeRadio==1?config.detail.settle_accounts:config.detail.settle_accounts2" :data-source="table.tableData">
                  <template slot="_shop">
                    <div class="cursor-pointer">
                      <a-popover :title="null" trigger="click" placement="bottomLeft">
                        <template slot="content">
                          <a-select class="width-min-200" optionFilterProp="label" showSearch allowClear v-model="query.institution_id" @change="query.page = 1,getList()" placeholder="全部门店">
                            <a-select-option :value="undefined">全部门店</a-select-option>
                            <a-select-option v-for="it in shopList" :key="it.institution_id" :value="it.institution_id" :label="it.shop_name">
                              {{ it.shop_name }}
                            </a-select-option>
                          </a-select>
                        </template>
                        <span>
                          <i style="font-size:15px;color:#9EA6A4" class="meiye-icon meiye-mendian"></i>
                          门店
                          <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
                        </span>
                      </a-popover>
                      </a-dropdown>
                    </div>
                  </template>
                  <template slot="_time">
                    <!-- <div class="cursor-pointer">
                      <a-dropdown :trigger="['click']">
                        <span @click="e => e.preventDefault()">
                          <i style="font-size:15px;color:#9EA6A4" class="meiye-icon meiye-riqi"></i>
                          订单时间
                          <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
                        </span>
                        <a-menu slot="overlay">
                          <a-range-picker allowClear format="YYYY-MM-DD" @change="setMonth" />
                        </a-menu>
                      </a-dropdown>
                    </div> -->
                    <div class="cursor-pointer">
                      <a-popover :title="null" v-model="visibleTime" trigger="click" placement="bottomLeft">
                        <template slot="content">
                          <a-range-picker style="width:280px" format="YYYY-MM-DD" @change="setMonth" />
                        </template>
                        <span>
                          <i style="font-size:15px;color:#9EA6A4" class="meiye-icon meiye-riqi"></i>
                          订单时间
                          <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
                        </span>
                      </a-popover>
                    </div>
                  </template>
                  <template slot="_type">
                    <div class="cursor-pointer">
                      <a-dropdown :trigger="['click']">
                        <span @click="e => e.preventDefault()">
                          <i style="font-size:15px;color:#9EA6A4" class="meiye-icon  meiye-shouyi"></i>
                          收益类型
                          <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
                        </span>
                        <a-menu slot="overlay">
                          <a-menu-item v-if="modeRadio==2" @click="query.income_type = 0,getList()">
                            全部类型
                          </a-menu-item>
                          <a-menu-item v-for="v in queryStatusList" @click="query.income_type = v.status,getList()">
                            {{v.label}}
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </div>
                  </template>
                </a-table>
                <h2 class="twoh1">
                  <span class="tpointer"></span>
                  <span class="ttxt">合伙人权益</span>
                </h2>
                <a-table :scroll="{x:'100%'}" class="mgb-12 mgt-10 detailsTable" :pagination="false" row-key="partner_account_id" size="middle" :columns="config.detail.introducer_list" :data-source="detailInfo.current_benefit">
                  <template slot="_1" slot-scope="action, record">
                    <img class="wh30Img" :src="record.image" v-if="record.image" @click="handlePreview(record,'image/*')">
                    <span v-else>暂无图片</span>
                  </template>
                  <template slot="_2" slot-scope="action, record">
                    <a-button size="small" class="smallbtn" @click="openDesc(record.desc)">查看详情</a-button>
                  </template>
                  <!-- <template slot="_7" slot-scope="action, record">
                  <a-button size="small" class="smallbtn" @click="lookIntroducer(record)">查看</a-button>
                </template> -->
                </a-table>
              </div>
            </div>
            <div id="product-user" data-anchor="product-user">
              <div class="mgl-10 mgb-20 mgt-20">
                <h2 class="twoh1">
                  <span class="tpointer"></span>
                  <span class="ttxt">合伙人转介绍</span>
                </h2>
                <div class="total_balance"> <span class="mgr-20 span">转介绍人数</span> {{table_introducer.pagination.total}} <span style="font-size: 14px;font-weight: 500;color: #323F3E;margin-left:8px">人</span></div>
                <a-table :scroll="{x:'100%'}" class="mgb-12 mgt-10 detailsTable" @change="tableChange" :pagination="table_introducer.pagination" row-key="id" size="middle" :columns="config.detail.referrer_list" :data-source="table_introducer.tableData">
                  <template slot="_client">
                    <div class="cursor-pointer">
                      <a-popover :title="null" trigger="click" placement="bottomLeft">
                        <template slot="content">
                          <a-input ref="getfocus" v-model="query_introducer.keywords" @change="getIntroducerList" style="width: 200px;" placeholder="搜索客户姓名/手机号">
                            <i slot="prefix" style="font-size:9px" class="meiye-icon meiye-sousuo"></i>
                          </a-input>
                        </template>
                        <span>
                          <i style="font-size:15px;color:#9EA6A4" class="meiye-icon  meiye-kehu"></i>
                          客户
                          <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
                        </span>
                      </a-popover>
                    </div>
                  </template>
                  <template slot="_4" slot-scope="action, record">
                    <span class="theme-color cursor-pointer" @click="checkFile(record)">{{record.nickname}} {{ record.mobile}}</span>
                  </template>
                  <template slot="_8" slot-scope="action, record">
                    <a-button size="small" class="smallbtn" @click="openIntroducer(record)">查看</a-button>
                  </template>
                </a-table>
              </div>
            </div>
            <div id="product-file" data-anchor="product-file">
              <div class="history-top cursor-pointer mgb-20" @click="showHistory=!showHistory">
                <span class="mgr-12">已失效权益，仅用于历史追溯</span>
                <span class="text" v-if="showHistory">收起 <a-icon style="font-size:9px" type="up" /></span>
                <span class="text" v-else>展开 <a-icon style="font-size:9px" type="down" /></span>
              </div>
              <History :shopList="shopList" @todetail="todetail" v-if="showHistory"></History>
            </div>
          </a-layout-content>
        </a-layout>
      </div>

      <a-modal v-model="desc_model.visible" title="权益描述" :footer="null" @cancel="desc_model.visible=false" :width="700">
        <div class="desc_css" v-html="desc_model.conter">{{desc_model.conter}}</div>
      </a-modal>
      <a-modal v-model="detail_model.visible" title="钱包明细" :footer="null" @cancel="detail_model.visible=false" :width="950">
        <a-table :scroll="{x:'100%'}" class="detailsTable" @change="tableChangePlan" :pagination="table_plan.pagination" row-key="id" size="middle" :columns="config.detail.plan_detail_list" :data-source="table_plan.tableData">
          <template slot="_0" slot-scope="action, record">
            <a-tooltip placement="top">
              <template slot="title">
                {{record.shop_name || '--'}}
              </template>
              {{record.shop_name || '--'}}
            </a-tooltip>
          </template>
          <template slot="_1" slot-scope="action, record">
            <a-tooltip placement="top">
              <template slot="title">
                {{record.partner_plan_name || '--'}}
              </template>
              {{record.partner_plan_name || '--'}}
            </a-tooltip>
          </template>
          <template slot="_6" slot-scope="action, record">
            <a-tooltip placement="top">
              <template slot="title">
                {{record.description || '--'}}
              </template>
              {{record.description || '--'}}
            </a-tooltip>
          </template>
          <template slot="_shop">
            <div class="cursor-pointer">
              <a-popover :title="null" trigger="click" placement="bottomLeft">
                <template slot="content">
                  <a-select class="width-min-200" optionFilterProp="label" showSearch allowClear v-model="query_plan.institution_id" @change="query_plan.page = 1,getArchivesDetail()" placeholder="全部门店">
                    <a-select-option :value="undefined">全部门店</a-select-option>
                    <a-select-option v-for="it in shopList" :key="it.institution_id" :value="it.institution_id" :label="it.shop_name">
                      {{ it.shop_name }}
                    </a-select-option>
                  </a-select>
                </template>
                <span>
                  <i style="font-size:15px;color:#9EA6A4" class="meiye-icon meiye-mendian"></i>
                  门店
                  <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
                </span>
              </a-popover>
              </a-dropdown>
            </div>
          </template>
          <template slot="_time">
            <div class="cursor-pointer">
              <a-dropdown :trigger="['click']">
                <span @click="e => e.preventDefault()">
                  <i style="font-size:15px;color:#9EA6A4" class="meiye-icon meiye-riqi"></i>
                  时间
                  <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
                </span>
                <a-menu slot="overlay">
                  <a-range-picker style="width:260px;margin:0 4px" allowClear format="YYYY-MM-DD" @change="setMonthDetail" />
                </a-menu>
              </a-dropdown>
            </div>
          </template>
        </a-table>
      </a-modal>
      <a-modal v-model="user_model.visible" title="查看" :footer="null" @cancel="user_model.visible=false" :width="950">
        <div id="product-base" data-anchor="product-base">
          <div class="detail-title">
            <div class="y-flex">
              <a-avatar shape="square" style="width:50px;height:50px" class="img" :src="table_user.dataList.avatar" />
              <div>
                <div style="margin-bottom:4px">
                  <span class="title" style="font-size: 14px;">{{table_user.dataList.name || '--'}} {{table_user.dataList.mobile || '--'}} </span>
                  <span class="mgl-12" size="small" :class="table_user.dataList.plan?.status == 0 ? 'mrj-span-tag-green' : 'mrj-span-tag-red'">{{table_user.dataList.plan?.status == 0? '合伙中' : '已到期'}}</span>
                </div>
                <div class="text-sm">用户ID：{{table_user.dataList.uid || '--'}}
                  <a-divider type="vertical" style="height: 9px; background-color: #DDE8E7;" />
                  <span>门店：{{table_user.dataList.shop_abbreviation || '--'}}</span>
                </div>
              </div>
            </div>
            <a-button type="primary" class="table-btn-clk" @click="checkFile(table_user.dataList)">查看客户档案</a-button>
          </div>
          <a-row class="mgt-12">
            <a-col :span="12">
              <div class="item-r-l">
                <span class="lText">合伙人方案</span>
                <span class="rText">{{table_user.dataList.plan?.partner_plan_name || '--'}}</span>
              </div>
            </a-col>
            <a-col :span="12">
              <div class="item-r-l">
                <span class="lText">录入时间</span>
                <span class="rText">{{table_user.dataList.invitation_time || '--'}}</span>
              </div>
            </a-col>
            <a-col :span="12">
              <div class="item-r-l">
                <span class="lText">佣金订单数</span>
                <span class="rText">{{table_user.dataList.commission_orders || '--'}}</span>
              </div>
            </a-col>
            <a-col :span="12">
              <div class="item-r-l">
                <span class="lText">录入人</span>
                <span class="rText">{{table_user.dataList.plan?.entry_admin_name || '--'}}</span>
              </div>
            </a-col>
          </a-row>
          <div>
            <a-table :scroll="{x:'100%'}" @change="tableChangeUser" class="mgb-12 mgt-10 detailsTable" :pagination="table_user.pagination" row-key="sn" size="middle" :columns="config.detail.table_user" :data-source="table_user.tableData">
              <template slot="_0" slot-scope="action, record">
                <a-tooltip placement="top">
                  <template slot="title">
                    {{record.sn || '--'}}
                  </template>
                  {{record.sn || '--'}}
                </a-tooltip>
              </template>
              <template slot="_2" slot-scope="action, record">
                <span v-if="record.status==1" class="theme-color">已结算</span>
                <span v-else-if="record.status==0">待结算</span>
                <span v-else-if="record.status==2">已废弃</span>
                <span v-else>--</span>
              </template>
              <template slot="_order">
                <div class="cursor-pointer">
                  <a-popover :title="null" trigger="click" placement="bottomLeft">
                    <template slot="content">
                      <a-input ref="getfocus" v-model="query_user.order_no" @change="getInitHeadList" style="width: 200px;" placeholder="搜索佣金订单编号">
                        <i slot="prefix" style="font-size:9px" class="meiye-icon meiye-sousuo"></i>
                      </a-input>
                    </template>
                    <span>
                      <i style="font-size:15px;color:#9EA6A4" class="meiye-icon meiye-bianhao"></i>
                      佣金订单编号
                      <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
                    </span>
                  </a-popover>
                </div>
              </template>
              <template slot="_8" slot-scope="action, record">
                <a-button size="small" class="smallbtn" @click="todetail(record)">详情</a-button>
              </template>
            </a-table>
          </div>
        </div>
      </a-modal>
    </template>
    <template v-else>
      <BonusDetail></BonusDetail>
    </template>
  </div>
</template>

<script>
import storage from 'store'
import config from '../config'
import History from './history.vue'
import BonusDetail from './bonusDetail.vue'
import moment from 'moment';
import Msg from '@/components/mrj-menu/msg'
import { partnerArchivesDetail, getInstitutionList, partnerArchivesDraft, partnerArchivesSettled, partnerArchivesInviter, partnerArchivesAccDetail, partnerArchivesInviterHead, partnerArchivesInviterList } from '@/api/partner'
import TableFilter from '@/components/intimate/tableFilter/index'
export default {
  components: { History, BonusDetail, TableFilter },
  // props: {
  //   shopList: {
  //     type: Array,
  //     default: () => []
  //   },
  // },
  data() {
    return {
      config,
      shopList: [],
      flitList: [
        { key: '门店', value: [], paramas: 'institution_id' },
        { key: '收益类型', value: [], paramas: 'income_type' },
        { key: '订单时间', value: [], paramas: 'dateRange' },
      ],
      detailInfo: {},
      activeKey: 'product-base',
      showType: true,
      imgflag: false,
      showHistory: false,
      visibleTime: false,
      modeRadio: 1,
      queryStatusList: [
        {
          status: 1,
          label: "佣金",
        },
        {
          status: 2,
          label: "分红",
        },
      ],
      queryStatusListAll: [
        {
          status: 0,
          label: "全部类型",
        },
        {
          status: 1,
          label: "佣金",
        },
        {
          status: 2,
          label: "分红",
        },
      ],
      query: {
        per_page: 10,
        page: 1,
        partner_id: undefined,
        page_type: 1, // 页面数据类型:1-当期数据;2-归档数据
        institution_id: undefined,
        income_type: undefined,
        start_date: undefined,
        end_date: undefined,
      },
      query_introducer: {
        per_page: 10,
        page: 1,
        partner_id: '',
        type: 0,
        keywords: undefined
      },
      table_introducer: {
        tableData: [],
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: false,
          showQuickJumper: false,
        },
      },
      table: {
        tableData: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: false,
          showQuickJumper: false,
        },
      },
      query_plan: {
        partner_id: undefined,
        institution_id: undefined,
        start_date: undefined,
        end_date: undefined,
        per_page: 10,
        page: 1,
      },
      table_plan: {
        tableData: [],
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: false,
          showQuickJumper: false,
        },
      },
      query_user: {
        partner_id: undefined,
        shop_id: undefined,
        partner_plan_id: undefined,
        user_id: undefined,
        order_no: undefined,
        per_page: 10,
        page: 1,
      },
      table_user: {
        dataList: '',
        tableData: [],
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: false,
          showQuickJumper: false,
        },
      },
      desc_model: {
        visible: false,
        conter: ''
      },
      detail_model: {
        visible: false
      },
      user_model: {
        visible: false,
      }
    }
  },
  watch: {
    modeRadio: {
      handler(val) {
        if (val == 1) {
          this.query.income_type = 1
        } else {
          this.query.income_type = 0
        }
        this.getList()
      },
      deep: true
    },
    'query.institution_id': {
      handler(val) {
        console.log('val :>> ', val);
        if (!val) {
          this.flitList[0].value = []
        }
        let str = this.shopList.find(v => v.institution_id == val);
        if (str) {
          this.flitList[0].value = [str.shop_name]
        }
      }
    },
    'query.income_type': {
      handler(val) {
        console.log('val :>> ', val);
        if (!val) {
          this.flitList[1].value = []
        }
        let arr = this.modeRadio == 2 ? this.queryStatusListAll : this.queryStatusList
        let str = arr.find(v => v.status == val);
        if (str) {
          this.flitList[1].value = [str.label]
        }
      }
    },
  },
  created() {
    let self = this
    const { id } = self.$route.query;
    const data = {
      partner_id: id
    }
    if (!data.partner_id) return;
    this.query.partner_id = id;
    this.query_introducer.partner_id = id;
    this.query.partner_id = id;
    this.query_plan.partner_id = id;
    this.query_user.partner_id = id;
    partnerArchivesDetail(data).then(res => {
      self.detailInfo = res.data;
    })
    getInstitutionList(data).then(res => {
      self.shopList = res.data;
    })
    this.getList();
    this.getIntroducerList();

  },
  methods: {
    moment,
    clearfliterSingle(e) {
      console.log(e);
      if (e) {
        // 单个清除 根据业务 去除 
        if (e.paramas == 'dateRange') {
          this.query.start_date = undefined;
          this.query.end_date = undefined;
        } else if (e.paramas == 'income_type') {
          this.query[e.paramas] = this.modeRadio == 2 ? 0 : 1
        } else {
          this.query[e.paramas] = undefined
        }
        this.flitList.forEach(el => {
          if (el.paramas == e.paramas) {
            el.value = []
          }
        })
        this.getList()
      } else {
        // 全部清除
        this.clearfliterAll()
      }
    },
    // 清除 类别  分类 品牌  状态 恢复 初始值
    clearfliterAll() {
      // 清除分类
      this.flitList = [
        { key: '门店', value: [], paramas: 'institution_id' },
        { key: '收益类型', value: [], paramas: 'income_type' },
        { key: '订单时间', value: [], paramas: 'dateRange' },
      ]
      this.query = {
        per_page: 10,
        page: 1,
        partner_id: this.query.partner_id,
        page_type: 1, // 页面数据类型:1-当期数据;2-归档数据
        institution_id: undefined,
        income_type: this.modeRadio == 2 ? 0 : 1,
        start_date: undefined,
        end_date: undefined,
      }
      this.getList()
    },
    handlePreview(src, type) {
      this.$utils.previewImgVideo({ type: type, response: { data: { file_url: src.image } } })
    },
    // 获取结算列表
    getList() {
      let self = this;
      self.table.selectedRowKeys = []
      self.table.loading = true;
      const params = {
        ...self.query,
        income_type: self.query.income_type ? self.query.income_type : this.modeRadio == 2 ? 0 : 1,
      };
      if (this.modeRadio == 1) {
        return partnerArchivesDraft(params)
          .then((res) => {
            console.log(res.data, "data");
            const { data = [], total = 0 } = res.data;
            self.table.tableData = data;
            self.table.pagination = Object.assign(
              {},
              { pageSize: this.query.per_page, current: this.query.page },
              {
                total: total,
              }
            );
          })
          .finally((r) => {
            self.table.loading = false;
          });
      } else {
        return partnerArchivesSettled(params)
          .then((res) => {
            console.log(res.data, "data");
            const { data = [], total = 0 } = res.data;
            self.table.tableData = data;
            self.table.pagination = Object.assign(
              {},
              { pageSize: this.query.per_page, current: this.query.page },
              {
                total: total,
              }
            );
          })
          .finally((r) => {
            self.table.loading = false;
          });
      }

    },
    setMonth(rec) {
      this.visibleTime = false;
      this.query.start_date = rec[0] ? rec[0].format('YYYY-MM-DD') : '';
      this.query.end_date = rec[1] ? rec[1].format('YYYY-MM-DD') : '';
      if (this.query.start_date) {
        this.flitList[2].value = [this.query.start_date + '~' + this.query.end_date];
      } else {
        this.flitList[2].value = []
      }
      this.getList();
    },
    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination;
      this.query = Object.assign(this.query, {
        per_page: Number(pageSize),
        page: Number(current),
      });
      this.getList();
    },
    // 合伙人转介绍人
    tableChange(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination;
      this.query_introducer = Object.assign(this.query_introducer, {
        per_page: Number(pageSize),
        page: Number(current),
      });
      this.getIntroducerList();
    },
    getIntroducerList() {
      let self = this
      return partnerArchivesInviter(self.query_introducer).then(res => {
        console.log(res.data, "data");
        const { data = [], total = 0 } = res.data;
        self.table_introducer.tableData = data
        self.table_introducer.pagination = Object.assign(
          {},
          { pageSize: this.query_introducer.per_page, current: this.query_introducer.page },
          {
            total: total,
          }
        );
      })
    },
    // 查看权益卡 描述
    openDesc(conter) {
      this.desc_model = {
        visible: true,
        conter: conter
      }
    },
    // 查看转介绍人
    openIntroducer(item) {
      console.log('item :>> ', item);
      this.query_user.shop_id = item.shop_id;
      this.query_user.partner_plan_id = item.partner_plan_id;
      this.query_user.user_id = item.user_id;
      this.getInitHead();
      this.getInitHeadList();
      this.user_model.visible = true
    },
    getInitHead() {
      let self = this
      partnerArchivesInviterHead(self.query_user).then(res => {
        self.table_user.dataList = res.data;
      })
    },
    tableChangeUser(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination;
      this.query_user = Object.assign(this.query_user, {
        per_page: Number(pageSize),
        page: Number(current),
      });
      this.getInitHeadList();
    },
    getInitHeadList() {
      let self = this
      partnerArchivesInviterList(self.query_user).then(res => {
        console.log(res.data, "data");
        const { data = [], total = 0 } = res.data;
        self.table_user.tableData = data
        self.table_user.pagination = Object.assign(
          {},
          { pageSize: this.query_user.per_page, current: this.query_user.page },
          {
            total: total,
          }
        );
      })
    },
    // 合伙人收益查看
    lookIntroducer() { },
    // 查看客户档案
    checkFile(item) {
      console.log('item :>> ', item);
      // dispatch只接受对象作为参数
      window.microApp.dispatch({ user_id: item.user_id, url: '/user/usercenter/detail' })
    },
    // 查看明细
    setMonthDetail(rec) {
      this.query_plan.start_date = rec[0] ? rec[0].format('YYYY-MM-DD') : '';;
      this.query_plan.end_date = rec[1] ? rec[1].format('YYYY-MM-DD') : '';;
      this.getArchivesDetail();
    },
    tableChangePlan(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination;
      this.query_plan = Object.assign(this.query_plan, {
        per_page: Number(pageSize),
        page: Number(current),
      });
      this.getArchivesDetail();
    },
    getArchivesDetail() {
      let self = this
      return partnerArchivesAccDetail(self.query_plan).then(res => {
        console.log(res.data, "data");
        const { data = [], total = 0 } = res.data;
        self.table_plan.tableData = data
        self.table_plan.pagination = Object.assign(
          {},
          { pageSize: this.query_plan.per_page, current: this.query_plan.page },
          {
            total: total,
          }
        );
      })
    },
    openDetail() {
      this.getArchivesDetail();
      this.query_plan.institution_id = undefined;
      this.query_plan.start_date = undefined;
      this.query_plan.end_date = undefined;
      this.query_plan.per_page = 10;
      this.query_plan.page = 1;
      this.detail_model.visible = true
    },
    // 合伙人钱包>编辑
    operation() { },
    // 描点
    handleWrapScroll(rec) {
      this.activeKey = this.$utils.onHandleWrapScroll(rec, this.config.tabs)
    },
    // 去佣金单详情
    todetail(e) {
      this.showType = false;
      this.$router.push({ path: this.$route.path, query: { type: 1, id: e.id } })
    },
    // 去首页
    toIndex() {
      this.$router.push({ path: 'partnerMenuFile' })
      this.$emit('onCancel');
    },
    changeRoute() {
      this.$emit('onCancel')
      console.log('this.$route.fullpath :>> ', this.$route.fullpath);
      this.$router.push({ path: this.$route.fullpath });
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)
    }
  }
};
</script>

<style scoped lang="less">
.main-ctn-2 {
  padding: 40px 0 0 35px;
  margin: 12px;
  width: 100%;
  height: calc(100vh - 128px);
  background: #fff;
  overflow-y: auto;
}
.wh30Img {
  width: 42px;
  height: 32px;
  margin-right: 10px;
  box-shadow: 0px 2px 4px 0px rgba(204, 209, 228, 0.35);
  border-radius: 2px;
  border: 1px solid #a3a8ca;
}
.twoh3 {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  .tpointer {
    display: inline-block;
    width: 4px;
    height: 4px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 5px;
  }
  .ttxt {
    height: 18px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    margin-right: 8px;
  }
}
.desc_css {
  max-height: 64vh;
  overflow: auto;
}
.item-r-l {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  .rText {
    color: @fontColor1;
  }
  .lText {
    color: rgba(45, 56, 53, 0.8);
    width: 123px;
    display: block;
  }
}
.history-top {
  text-align: center;
  height: 31px;
  line-height: 31px;
  background: #fff0e8;
  border: 1px solid #ffd8c3;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f47c5b;
  .text {
    font-size: 12px;
    color: #b7978e;
  }
}
.total_balance {
  display: flex;
  align-items: center;
  background: #f3f9f8;
  border-radius: 4px;
  font-size: 22px;
  padding-left: 24px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: @baseColor11;
  .span {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323f3e;
  }
}
.twoh2 {
  display: flex;
  margin-bottom: 7px;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin: 5px 5px 0 0;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    line-height: 22px;
  }
  .tag-sm {
    height: 22px;
    background: #e6f5f4;
    border-radius: 11px;
    padding: 0 6px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(45, 56, 53, 0.8);
    line-height: 22px;
  }
}
.twoh1 {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 5px;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    margin-right: 8px;
  }
}

.y-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.detail-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .img {
    width: 88px;
    height: 88px;
    background: #cbe3e1;
    border-radius: 8px;
    margin-right: 18px;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
  }
  .text {
    font-size: 14px;
    font-weight: 400;
    color: #323f3e;
  }
  .text-sm {
    font-size: 14px;
    font-weight: 400;
    color: #778581;
  }
}
.card-bg {
  height: 150px;
  background: #f3f9f8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  .title {
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323f3e;
    line-height: 22px;
    margin-bottom: 15px;
    margin-left: 15px;
  }
  .money {
    height: 30px;
    font-size: 26px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    line-height: 30px;
  }
}
.shortcut-menu {
  width: 130px;
  :deep(.ant-anchor-wrapper) {
    background-color: transparent;
  }
}
/deep/.ant-table-middle
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th,
/deep/.ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th {
  padding: 1px 8px;
}
/deep/.detailsTable .ant-table-row > td:nth-child(1),
/deep/.detailsTable .ant-table-thead > tr:first-child > th:first-child {
  padding-left: 24px !important;
}
</style>